import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Button,
    Chip,
    Divider,
    FormControl,
    Grid,
    InputAdornment,
    Modal,
    Stack,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import { useEffect, useState } from 'react';
import { getServices } from 'services/schedule';

const AdditionalServices = ({ addededServices, setAddedServices, isDisabled, isEditable, guestCount, setGuestCount }) => {
    const [service, setService] = useState(null);
    const [services, setServices] = useState([]);
    const [serviceType, setServiceType] = useState(0);

    const [open, setOpen] = useState(false);

    const fetchServices = async () => {
        const { data } = await getServices();
        setServices(data);
    };

    useEffect(() => {
        fetchServices();
    }, []);

    useEffect(() => {
        if (open) setService(null);
    }, [open]);

    const tooltipText = 'Disponível para convidados externos ou reuniões com mais de 1 hora e 10 minutos de duração';

    return (
        <Tooltip title={isDisabled ? tooltipText : ''} arrow>
            <Accordion variant="outlined" disabled={isDisabled} expanded={!isDisabled}>
                <AccordionSummary expandIcon={<PlaylistAddIcon />}>
                    <Typography variant="h5" component="h2" color="GrayText">
                        Serviços adicionais
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {addededServices?.map((service) => (
                                <Chip
                                    color="success"
                                    label={`${service.title}`}
                                    sx={{ mr: 1, mb: 1 }}
                                    key={service.id}
                                    clickable={isEditable}
                                    onDelete={
                                        isEditable
                                            ? (e) => {
                                                  setAddedServices(addededServices.filter((s) => s.id !== service.id));
                                                  e.preventDefault();
                                              }
                                            : null
                                    }
                                />
                            ))}
                            {addededServices?.length == 0 && (
                                <Stack direction="row" justifyContent="center">
                                    <Typography variant="legend" component="p" fontSize={12}>
                                        Nenhum serviço adicionado
                                    </Typography>
                                </Stack>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ my: 0 }} />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <GroupIcon fontSize="small" sx={{ mr: 1 }} />
                                <FormControl required fullWidth>
                                    <TextField
                                        type="text"
                                        name="guest_count"
                                        value={guestCount}
                                        label="Quantidade de pessoas"
                                        onChange={(e) => {
                                            setGuestCount(e.target.value);
                                        }}
                                        size="small"
                                        disabled={!isEditable}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ minHeight: 40 }}>
                                {(isEditable && (
                                    <Button
                                        variant="text"
                                        color="success"
                                        startIcon={<AddCircleOutlineOutlinedIcon />}
                                        onClick={() => setOpen(true)}
                                        //
                                    >
                                        Adicionar
                                    </Button>
                                )) || (
                                    <Typography variant="legend" component="p" color="red" fontSize={11}>
                                        Tempo limite para adicionar serviços
                                    </Typography>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </AccordionDetails>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4
                        }}
                    >
                        <Grid container spacing={1} display="flex" direction="row" justifyContent="space-between" alignItems="center">
                            <Typography color="GrayText" variant="h3" component="h1" fontWeight={100}>
                                Novo serviço
                            </Typography>
                            <Grid item display="flex" justifyContent="flex-end">
                                <CloseIcon onClick={() => setOpen(false)} style={{ cursor: 'pointer' }} />
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 1 }} />
                        <Grid container spacing={1} display="flex" justifyContent="center" alignItems="baseline">
                            <Tabs value={serviceType} onChange={(e, value) => setServiceType(value)}>
                                <Tab icon={<LocalCafeIcon />} label="COFFE BREAK" />
                            </Tabs>
                        </Grid>
                        <Divider sx={{ my: 1 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl required fullWidth>
                                    <Autocomplete
                                        label="Serviço"
                                        value={service}
                                        onChange={(e, value) => setService(value)}
                                        options={services.filter((s) => s.ativo == true)}
                                        getOptionLabel={(option) => option.title}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Stack>
                                        {addededServices?.find((s) => s.id === service?.id) && (
                                            <Typography color="error" variant="legend" component="p" fontSize={12}>
                                                Este serviço já foi adicionado
                                            </Typography>
                                        )}
                                    </Stack>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={() => {
                                            setAddedServices([...addededServices, service]);
                                            setOpen(false);
                                        }}
                                        disabled={addededServices?.find((s) => s.id === service?.id) || !service ? true : false}
                                    >
                                        Adicionar
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </Accordion>
        </Tooltip>
    );
};

export default AdditionalServices;
