import { app } from './Api';

const postSchedule = async (data) => {
    return app.post('schedule', data);
};
const updateSchedule = async (id, data) => {
    return app.put('schedule/' + id, data);
};
const deleteSchedule = async (id, options) => {
    return app.delete(`schedule/${id}`, { data: options });
};
const checkIn = async (id) => {
    return app.post(`schedule/${id}/checkin`);
};
const locationCheck = async (id) => {
    return app.post(`location/${id}/check`);
};
const searchSchedule = async (keyword) => {
    return app.get('schedule/search/' + keyword);
};
const getScheduleSettings = async () => {
    return app.get('schedule/setting/list');
};
const updateScheduleSettings = async (data) => {
    return app.post('schedule/setting', data);
};
const getServices = async () => {
    return app.get('service');
};

export {
    postSchedule,
    updateSchedule,
    deleteSchedule,
    checkIn,
    searchSchedule,
    getScheduleSettings,
    updateScheduleSettings,
    getServices,
    locationCheck
};
