import * as React from 'react';
import {
    Typography,
    Box,
    Modal,
    Card,
    CardHeader,
    CardContent,
    IconButton,
    CardMedia,
    Stack,
    Chip,
    Tooltip,
    CardActions,
    Grid
} from '@mui/material';
import NotesIcon from '@mui/icons-material/Notes';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import moment from 'moment';
import 'moment/locale/pt-br';
import ScheduleActions from './ScheduleActions';

const weekdays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

const ViewScheduleModal = ({ open, onClose, schedule, onUpdateSchedule, onDeleteSchedule, setUpdateOptions, setDeleteOptions }) => {
    const handleUpdate = () => {
        onClose();
        onUpdateSchedule();
    };

    const handleDelete = () => {
        onClose();
        onDeleteSchedule();
    };

    const handleClose = () => {
        onClose();
    };

    const checkInCompleted = () => {
        return schedule?.checked_at != null;
    };

    return (
        <Modal open={open} onClose={handleClose} sx={{ height: '100vh', overflowY: 'scroll' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 0,
                    minWidth: 400,
                    maxWidth: 500,
                    maxHeight: '92vh',
                    overflowY: 'auto',
                    boxShadow: 24,
                    padding: 0
                }}
            >
                <Card>
                    <CardHeader
                        action={
                            <>
                                <Box display="flex" justifyContent="flex-end" alignItems="center">
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </>
                        }
                        title={schedule?.title}
                        subheader={moment(schedule?.start).locale('pt-br').format('LL')}
                    />
                    <CardMedia component="img" height="240" image="/img/room.jpg" />
                    <CardContent sx={{ py: 1, px: 0 }}>
                        <Grid container spacing={2} alignItems="top" sx={{ py: 2 }}>
                            <Grid item xs={2} display="flex" justifyContent="flex-end">
                                <AccessTimeIcon fontSize="small" />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body1">
                                    {weekdays && weekdays[moment(schedule?.start).day()]}
                                    <>, </>
                                    {moment(schedule?.start).locale('pt-br').format('L')}
                                    <> - </>
                                    {moment(schedule?.start).locale('pt-br').format('HH:mm')}
                                    <> às </>
                                    {moment(schedule?.end).locale('pt-br').format('HH:mm')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} display="flex" justifyContent="flex-end">
                                <EventAvailableOutlinedIcon fontSize="small" />
                            </Grid>
                            <Grid item xs={10}>
                                {checkInCompleted() ? (
                                    <Chip size="small" color="success" label="Realizado" sx={{ borderRadius: 1, p: 0 }} />
                                ) : (
                                    <Chip size="small" color="warning" label="Pendente" sx={{ borderRadius: 1, p: 0 }} />
                                )}
                            </Grid>
                            <Grid item xs={2} display="flex" justifyContent="flex-end">
                                <PlaceOutlinedIcon fontSize="small" />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body1">{schedule?.location?.description}</Typography>
                            </Grid>
                            <Grid item xs={2} display="flex" justifyContent="flex-end">
                                <AssignmentIndOutlinedIcon fontSize="small" />
                            </Grid>
                            <Grid item xs={10}>
                                <Tooltip title={schedule?.owner?.email} arrow>
                                    <Chip
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        label={schedule?.owner?.name}
                                        sx={{ borderRadius: 1, p: 0 }}
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={2} display="flex" justifyContent="flex-end">
                                <EmailIcon fontSize="small" />
                            </Grid>
                            <Grid item xs={10}>
                                {schedule?.guests?.length > 0 && (
                                    <Stack display="inline-block">
                                        {schedule?.guests?.map((guest) => (
                                            <Chip
                                                size="small"
                                                variant="outlined"
                                                key={guest.email}
                                                label={guest.email}
                                                sx={{ borderRadius: 1, p: 0, mb: 0.3, mr: 0.3 }}
                                            />
                                        ))}
                                    </Stack>
                                )}
                                {!schedule?.guests?.length && (
                                    <Typography variant="legend" color="gray">
                                        Sem convidados
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={2} display="flex" justifyContent="flex-end">
                                <NotesIcon fontSize="small" />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body1">{schedule?.description}</Typography>
                                {!schedule?.description && (
                                    <Typography variant="legend" color="gray">
                                        Sem descrição
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions sx={{ p: 3 }}>
                        <ScheduleActions
                            schedule={schedule}
                            onUpdateSchedule={handleUpdate}
                            onDeleteSchedule={handleDelete}
                            setUpdateOptions={setUpdateOptions}
                            setDeleteOptions={setDeleteOptions}
                        />
                    </CardActions>
                </Card>
            </Box>
        </Modal>
    );
};

export default ViewScheduleModal;
