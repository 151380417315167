import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    type: 'group',
    title: 'Menu Principal',
    children: [
        {
            id: 'default',
            title: 'Reservar Sala',
            type: 'item',
            url: '/agendamentos',
            icon: EditCalendarOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'timeline',
            title: 'Agenda do Dia',
            type: 'item',
            url: '/timeline',
            icon: EventNoteOutlinedIcon,
            breadcrumbs: false,
            color: 'success',
            variant: 'success',
            target: '_blank'
        }
    ]
};

export default dashboard;
