import * as React from 'react';
import { useState } from 'react';

import { Typography, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

import { deleteSchedule } from 'services/schedule';

import { CircularProgress } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

const DeleteSchedule = ({ open, close, schedule, deleteOptions, fetchEvents }) => {
    const [loading, setLoading] = useState(false);

    const handleDelete = () => {
        setLoading(true);
        deleteSchedule(schedule.id, deleteOptions)
            .then((resp) => {
                fetchEvents();
                close();
                enqueueSnackbar('Agendamento excluído com sucesso!', { variant: 'success' });
                setLoading(false);
            })
            .catch((e) => {
                const message = e.response.data?.error || 'Erro ao excluir agendamento!';
                enqueueSnackbar(message, { variant: 'error' });
                setLoading(false);
            });
    };

    const handleCancel = () => {
        close();
    };

    return (
        <Dialog
            open={open}
            onClose={() => close()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            //
        >
            <DialogTitle id="alert-dialog-title">{'Atenção!'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">Você tem certeza que deseja cancelar este agendamento?</DialogContentText>
            </DialogContent>
            <DialogActions>
                {loading && (
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                        <CircularProgress size={20} sx={{ mr: 2 }} />
                        <Typography variant="legend" component="p" color="gray">
                            Processando...
                        </Typography>
                    </Box>
                )}
                <Button onClick={handleCancel}>Não</Button>
                <Button onClick={handleDelete}>Sim</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteSchedule;
