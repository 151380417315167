import { useEffect, useState } from 'react';
import { Autocomplete, Avatar, Chip, Grid, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';

import { getContacts } from 'services/contact';

const SelectScheduleGuests = ({ guests, setGuests }) => {
    const [contacts, setContacts] = useState([]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        const fetchContacts = async () => {
            const { data } = await getContacts();
            setContacts(data);
        };

        fetchContacts();
    }, []);

    return (
        <Autocomplete
            multiple
            freeSolo={true}
            options={contacts.map((contact) => contact.email)}
            filterSelectedOptions
            value={guests}
            onChange={(e, value) => setGuests(value)}
            inputValue={inputValue}
            onInputChange={(e, value) => setInputValue(value)}
            open={inputValue.length > 0}
            onKeyDown={(e) => {
                if (e.key === ';') {
                    e.preventDefault();
                    const email = inputValue.trim();
                    if (email.length > 0) {
                        setGuests([...guests, email]);
                        setInputValue('');
                    }
                }
            }}
            onPaste={(e) => {
                e.preventDefault();
                const clipboardData = e.clipboardData.getData('Text');
                const emails = clipboardData.split(/[\s,;]+/);
                setGuests([...guests, ...emails]);
            }}
            noOptionsText="Nenhum contato encontrado"
            loadingText="Carregando..."
            clearText="Limpar"
            closeText="Fechar"
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Convidados"
                    placeholder="Selecione os convidados"
                    //
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    const guest = contacts.find((contact) => contact.email === option);
                    return (
                        <Tooltip key={index} title={guest?.email || ''} arrow>
                            <Chip
                                variant="filled"
                                label={guest?.name || option}
                                {...getTagProps({ index })}
                                sx={{ borderRadius: 1, p: 0, mb: 0.3, mr: 0.3, border: '1px solid #ccc' }}
                            />
                        </Tooltip>
                    );
                })
            }
            renderOption={(props, option, { selected }) => {
                const { email, name } = contacts.find((contact) => contact.email === option) || {};
                return (
                    <li {...props}>
                        <Grid
                            container
                            alignItems="center"
                            spacing={2}
                            fullWidth
                            sx={[{ py: 1 }, { '&:hover': { backgroundColor: '#dfdfdf' } }]}
                        >
                            <Grid item>
                                <Avatar sx={{ width: 24, height: 24, p: 2 }}>{name?.charAt(0)}</Avatar>
                            </Grid>
                            <Grid item xs sx={{ fontWeight: 'bold' }}>
                                {name}
                                <Typography variant="body2" color="text.secondary">
                                    {email}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
            slotProps={{ popper: { sx: { zIndex: 15, boxShadow: 4, borderRadius: 2 }, disablePortal: 'disablePortal' } }}
        />
    );
};

export default SelectScheduleGuests;
