import * as React from 'react';
import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Button, Link, MenuItem, MenuList, Paper, Popper, Stack, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

import moment from 'moment';

const ScheduleActions = ({ schedule, onUpdateSchedule, onDeleteSchedule, setUpdateOptions, setDeleteOptions }) => {
    const authUser = useSelector((state) => state.auth.user);

    const updateButtonRef = useRef(null);
    const deleteButtonRef = useRef(null);

    const [openUpdatePopper, setOpenUpdatePopper] = useState(false);
    const [openDeletePopper, setOpenDeletePopper] = useState(false);

    const handleUpdate = (options = {}) => {
        setUpdateOptions(options);
        onUpdateSchedule();
    };

    const handleDelete = (options = {}) => {
        setDeleteOptions(options);
        onDeleteSchedule();
    };

    const canEditSchedule = () => {
        const isUpcoming = moment().isBefore(schedule.start);
        const isOwnerSchedule = schedule.user_id === authUser.id;
        return (isOwnerSchedule || authUser.role == 'admin') && isUpcoming;
    };

    const handleUpdateToggle = () => {
        if (!schedule?.recurrence_id) {
            handleUpdate();
            return;
        }

        setOpenUpdatePopper((prevOpen) => !prevOpen);
    };

    const handleDeleteToggle = () => {
        if (!schedule?.recurrence_id) {
            handleDelete();
            return;
        }

        setOpenDeletePopper((prevOpen) => !prevOpen);
    };

    const handleUpdateClose = (event) => {
        if (updateButtonRef.current && updateButtonRef.current.contains(event.target)) {
            return;
        }

        setOpenUpdatePopper(false);
    };

    const handleDeleteClose = (event) => {
        if (deleteButtonRef.current && deleteButtonRef.current.contains(event.target)) {
            return;
        }

        setOpenDeletePopper(false);
    };

    return (
        <Stack direction="row" spacing={1}>
            {canEditSchedule() && (
                <>
                    <Button
                        ref={updateButtonRef}
                        onClick={handleUpdateToggle}
                        variant="outlined"
                        sx={{ b: 1, borderRadius: 1, color: 'GrayText', borderColor: '#bbb' }}
                        startIcon={<EditCalendarIcon fontSize="small" color="primary" />}
                        endIcon={schedule?.recurrence_id ? <KeyboardArrowDownIcon fontSize="small" /> : ''}
                        disabled={!canEditSchedule()}
                    >
                        <Typography variant="span" color="GrayText" fontSize={13}>
                            Editar
                        </Typography>
                    </Button>
                    <Popper
                        sx={{ zIndex: 15, boxShadow: 4 }}
                        open={openUpdatePopper}
                        anchorEl={updateButtonRef.current}
                        placement="bottom-start"
                        disablePortal
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleUpdateClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    <MenuItem onClick={() => handleUpdate()}>Editar</MenuItem>
                                    <MenuItem onClick={() => handleUpdate({ update_upcoming: true })}>Editar próximos</MenuItem>
                                    <MenuItem onClick={() => handleUpdate({ update_recurrence: true })}>Editar todos</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popper>
                    <Button
                        ref={deleteButtonRef}
                        onClick={handleDeleteToggle}
                        variant="outlined"
                        color="error"
                        sx={{ b: 1, borderRadius: 1, color: 'GrayText', borderColor: '#bbb' }}
                        startIcon={<EventBusyOutlinedIcon fontSize="small" color="error" />}
                        endIcon={schedule?.recurrence_id ? <KeyboardArrowDownIcon fontSize="small" /> : ''}
                        disabled={!canEditSchedule()}
                    >
                        <Typography variant="span" color="GrayText" fontSize={13}>
                            Cancelar
                        </Typography>
                    </Button>
                    <Popper
                        sx={{ zIndex: 15, boxShadow: 4 }}
                        open={openDeletePopper}
                        anchorEl={deleteButtonRef.current}
                        placement="bottom-start"
                        disablePortal
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleDeleteClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    <MenuItem onClick={() => handleDelete()}>Cancelar</MenuItem>
                                    <MenuItem onClick={() => handleDelete({ cancel_upcoming: true })}>Cancelar próximos</MenuItem>
                                    <MenuItem onClick={() => handleDelete({ cancel_recurrence: true })}>Cancelar todos</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popper>
                </>
            )}
            {schedule?.external_meeting_url && (
                <Link href={schedule?.external_meeting_url} target="_blank" rel="noreferrer">
                    <Button
                        variant="outlined"
                        sx={{ b: 1, borderRadius: 1, color: 'GrayText', borderColor: '#bbb', '&:hover': { borderColor: '#6264A7' } }}
                        startIcon={<GroupsOutlinedIcon fontSize="small" sx={{ color: '#6264A7' }} />}
                    >
                        <Typography variant="span" color="GrayText" fontSize={13}>
                            Teams
                        </Typography>
                    </Button>
                </Link>
            )}
        </Stack>
    );
};

export default ScheduleActions;
